<template>
    <div>
        <div>
            <div class="flex-end">
                <Checkbox v-model="isOpenBrand" @on-change="isOpenBrandChange">
                    只看感兴趣的品牌
                </Checkbox>
            </div>
            <template v-if="data.length">
                <div v-for="it of data" :key="it.id" class="relative mb20">
                    <Button
                        class="mr10 absolute"
                        type="primary"
                        style="top: 20px; right: 20px"
                        @click="handleBid(it)"
                    >
                        去抢单
                    </Button>
                    <div class="flex-v-center mb20">
                        <div class="count-down">
                            <CountDown :end-time="getEndTime(it)" />
                        </div>
                        <div class="ml20 c-red">
                            已响应商家数量<span class="bold h3">{{
                                it.supplierCount
                            }}</span
                            >个
                        </div>
                    </div>
                    <Row class="mb10">
                        <Col :span="8">
                            <div>
                                <span class="field-title">需求编号:</span>
                                <span class="bold c-black">{{
                                    it.needSn || '--'
                                }}</span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">发布时间:</span>
                                <span class="bold c-black">{{
                                    formatTime(it.publishTime)
                                }}</span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">买家类型:</span>
                                <span class="bold c-black">{{
                                    it.orgQualification || '--'
                                }}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row class="mb10">
                        <Col :span="8">
                            <div class="flex-v-center">
                                <span class="mr10">买家区域:</span>
                                <span
                                    class="bold c-black inline-block ellipse"
                                    style="width: 60%"
                                >
                                    {{ it.receiverTerminalAddress || '--' }}
                                </span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">买家履约率:</span>
                                <span class="bold c-black">-</span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">品牌:</span>
                                <span class="bold c-black">
                                    {{
                                        getNeedListItemType(it, 'productBrand')
                                    }}
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row class="mb10">
                        <Col :span="8">
                            <div>
                                <span class="field-title">货号:</span>
                                <span class="bold c-black">
                                    {{ getNeedListItemType(it, 'productNo') }}
                                </span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">名称:</span>
                                <span class="bold c-black">
                                    {{ getNeedListItemType(it, 'productName') }}
                                </span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">图片:</span>
                                <span class="bold c-black">
                                    <ViewImg
                                        v-if="it.productPic"
                                        :src="it.productPic"
                                    />
                                    <span v-else>--</span>
                                </span>
                            </div>
                        </Col>
                    </Row>

                    <Row class="mb10">
                        <Col :span="8">
                            <div>
                                <span class="field-title">数量:</span>
                                <span class="bold c-black">
                                    {{
                                        getNeedListItemType(
                                            it,
                                            'productQuantity',
                                        )
                                    }}
                                    {{ getNeedListItemType2(it, 'unit') }}
                                </span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">产品类型:</span>
                                <span class="bold c-black">
                                    {{ getCategoryTypeName(it) }}
                                </span>
                            </div>
                        </Col>
                        <Col :span="8">
                            <div>
                                <span class="field-title">订单流程平台:</span>
                                <span class="bold c-black">
                                    {{ getPlatform(it) }}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </template>
            <div v-else class="tc bold" style="line-height: 100px">
                暂无数据
            </div>
        </div>

        <div class="flex-end">
            <Page
                :total="pageOpts.total"
                :page-size="pageOpts.size"
                :current="pageOpts.current"
                show-sizer
                show-elevator
                class="mt10"
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>
    </div>
</template>

<script>
import CountDown from '@/components/CountDown.vue';
import ViewImg from '@/components/ViewImg.vue';

export default {
    components: { ViewImg, CountDown },
    data() {
        return {
            data: [],
            pageOpts: {
                total: 0,
                size: 10,
                current: 1,
            },
            isOpenBrand: false,
        };
    },
    created() {
        this.getData();
    },
    mounted() {
        this.setTimer();
    },
    beforeDestroy() {
        this.clearTimer();
    },
    methods: {
        getPlatform(row) {
            let type = _.get(row, 'purchasePlatform');
            if (!type) {
                return '--';
            }

            return $const.optionsMap.purchasePlatform[type];
        },
        getCategoryTypeName(row) {
            let type = _.get(row, 'needItemList.0.productCategoryType');
            if (!type) {
                return '--';
            }
            return $const.optionsMap.productCategoryType[type];
        },
        getNeedListItemType(row, name) {
            return _.get(row, `needItemList.0.${name}`) || '--';
        },
        getNeedListItemType2(row, name) {
            return _.get(row, `needItemList.0.${name}`);
        },
        formatTime(time) {
            return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '--';
        },
        async getData(silent = false) {
            window.registerUpdateRunner = () => this.getData(); // 仅此刷新

            let params = {
                pageSize: this.pageOpts.size,
                currentPage: this.pageOpts.current,
                tab: 2,
                status: 2,
                isOpenBrand: this.isOpenBrand ? 1 : 0,
            };
            let res = await $service.needIntent.pageBySupplier(params, {
                silent,
            });

            let data = _.get(res, 'data') || {};

            let total = _.get(data, 'total') || 0;
            // let current = _.get(data, 'current') || 0;
            // let size = _.get(data, 'size') || 0;
            let records = _.get(data, 'records') || [];
            // this.pageOpts = {total, size, current};
            records.forEach((it) => {
                it.productPic = _.get(it, 'needItemList[0].productPic');
            });
            this.pageOpts.total = total;
            this.data = records;
        },
        pageChange(current) {
            this.pageOpts.current = current;
            this.getData();
        },
        pageSizeChange(size) {
            this.pageOpts.size = size;
            this.getData();
        },
        // 操作
        handleView(row) {
            this.$router.push({
                path: '/home/seller/view',
                query: {
                    id: row.id,
                },
            });
        },
        handleBid(row) {
            this.$router.push({
                path: '/home/seller/bid',
                query: {
                    id: row.id,
                },
            });
        },
        getEndTime(it) {
            return dayjs(it.publishTime).add(it.expireTimeType, 'minute');
        },
        isOpenBrandChange() {
            this.getData();
        },
        setTimer() {
            $util.timerUtil.setTimer(() => {
                this.getData(true);
            });
        },
        clearTimer() {
            $util.timerUtil.clearTimer();
        },
    },
};
</script>
<style scoped>
.count-down {
    min-width: 116px;
    padding: 0 5px;
    line-height: 28px;
    text-align: center;
    background: linear-gradient(228deg, #503517 0%, #191c17 100%);
    border-radius: 2px;

    font-size: 12px;
    font-weight: 400;
    color: #ffbb33;
}
</style>
