<template>
    <div class="view-img-wrapper" @click.stop.prevent="onPreview">
        <slot>
            <img v-if="src" class="img" :src="src" :alt="src" :style="styles" />
            <span v-else>{{ $emptyContent }}</span>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        src: {},
        maxWidth: {},
        maxHeight: {},
    },
    computed: {
        styles() {
            return {
                maxWidth: this.maxWidth || '100%',
                maxHeight: this.maxHeight || '34px',
            };
        },
    },
    methods: {
        onPreview() {
            this.$viewerApi({
                images: [this.src],
            });
        },
    },
};
</script>

<style lang="less" scoped>
.view-img-wrapper {
    display: inline-block;
    position: relative;

    .masks {
        position: absolute;
        display: none;
        width: 100%;
        text-align: center;
        cursor: pointer;

        .mask-text {
            font-size: 10px;
            color: #ffffff;
            background: #0a5495;
        }
    }
    &:hover {
        .masks {
            display: block;
        }
    }
}
</style>
