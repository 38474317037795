import currentOrgMixin from '@/mixins/currentOrg.mixin';
import userInfoMixin from '@/mixins/userInfo.mixin';

// usage:
// src/pages/home/seller/BidIndex.vue
// src/pages/home/buyer/Bill/BillForm.vue

export default {
    mixins: [currentOrgMixin, userInfoMixin],
    data() {
        return {
            //0 不弹框 ，1（org!=null && org.status==0)：您加入或注册的企业申请还没有完成审核，暂时无法询货和抢单。 2（org 获取不到）：您需要先注册/加入企业才能够发采购需求 3（org!=null && org.status==2)：您加入或注册的企业的申请审核未通过，暂时无法询货和抢单。
            msgType: {
                show: false,
                content: '',
            },
            msgTypeEl: null,
        };
    },
    computed: {
        orgUser() {
            return {
                org: this.$currentOrg.org,
                userInfo: this.$userInfo,
            };
        },
    },
    watch: {
        'msgType.show'(show) {
            if (!show) {
                let msgTypeEl = this.msgTypeEl;
                if (msgTypeEl) {
                    msgTypeEl.onmousewheel = null;
                }
            }
        },
        orgUser: {
            handler() {
                this.msgType = {
                    show: false,
                    content: this.$emptyContent,
                };
                // 0 不弹框 ，1（org!=null && org.status==0)：您加入或注册的企业申请还没有完成审核，暂时无法询货和抢单。
                // 2（org 获取不到）：您需要先注册/加入企业才能够发采购需求
                // 3（org!=null && org.status==2)：您加入或注册的企业的申请审核未通过，暂时无法询货和抢单。
                let status = +_.get(this.$currentOrg.org, 'status');
                let userStatus = this.$userInfo.status;
                // 闪屏优化
                if (!Object.keys(this.$currentOrg).length) {
                    return;
                }
                if (_.isEmpty(this.$currentOrg.org)) {
                    this.msgType = {
                        show: true,
                        content: '您需要先注册/加入企业才能够抢单',
                    };
                } else if (status === 0) {
                    this.msgType = {
                        show: true,
                        content:
                            '您注册企业申请还没有完成审核，暂时无法询货和抢单',
                    };
                } else if (status === 2) {
                    this.msgType = {
                        show: true,
                        content:
                            '您加入企业的申请审核未通过，暂时无法询单和抢单。请联系管理员进行授权',
                    };
                } else if (status === 1 && userStatus === 2) {
                    // 增加userStatus判断拒绝情况
                    this.msgType = {
                        show: true,
                        content: '您还没有获取企业授权，暂时无法询货和抢单',
                    };
                } else if (status === 1 && userStatus === 0) {
                    this.msgType = {
                        show: true,
                        content: '您需要先注册/加入企业才能够抢单',
                    };
                }
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        let msgTypeEl = this.msgTypeEl;
        if (msgTypeEl) {
            msgTypeEl.onmousewheel = null;
        }
    },
    mounted() {
        let msgTypeEl = (this.msgTypeEl = document.querySelector(
            '[data-scroll-id="msg-type"]',
        ));
        if (msgTypeEl) {
            msgTypeEl.onmousewheel = () => false;
        }
    },
};
