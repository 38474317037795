<template>
    <div>
        <div>商家响应倒计时：{{ remainingTime }}</div>
    </div>
</template>

<script>
export default {
    name: 'CountDown',
    props: {
        endTime: {},
    },
    data() {
        return {
            // endTime: dayjs().add(1, 'day'), // 第一个倒计时结束时间,这里设置为明天的现
            interval: null,
            remainingTime: '',
        };
    },
    beforeDestroy() {
        clearInterval(this.interval);
        this.interval = null;
    },
    mounted() {
        this.interval = setInterval(() => {
            this.remainingTime = this.getRemainingTime(this.endTime);
        }, 1000);
    },
    methods: {
        getRemainingTime(endTime) {
            const now = dayjs();
            const diff = endTime.diff(now, 'second');

            if (diff <= 0) {
                clearInterval(this.interval);
                return '已结束';
            }

            const day = Math.floor(diff / (24 * 3600));
            const hour = Math.floor((diff - day * 24 * 3600) / 3600);
            const minute = Math.floor(
                (diff - day * 24 * 3600 - hour * 3600) / 60,
            );
            const second = diff % 60;

            // #105 去掉秒
            // return `${day}天${hour}小时${minute}分${second}秒`;
            // return `${day}天${hour}小时${minute}分`;

            let finalHour = day * 24 + hour;
            return `${finalHour}小时${minute}分`;
        },
    },
};
</script>
