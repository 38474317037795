<template>
    <div class="seller-index">
        <div class="user-banner mb10">
            <img
                class="wp100 vertical-mid"
                src="../../../assets/img/banner/seller.webp"
                alt=""
            />
        </div>

        <SellerBidTable ref="table" />

        <div class="i-modals" data-scroll-id="msg-type">
            <Modal
                v-model="msgType.show"
                title="小助提示"
                :closable="false"
                :mask-closable="false"
                :transfer="false"
                footer-hide
            >
                <p class="c-red bold tc pd24">
                    {{ msgType.content }}
                </p>
            </Modal>
        </div>
    </div>
</template>

<script>
import SellerBidTable from './Index/SellerBidTable.vue';
import msgType from '@/mixins/msg.type';
export default {
    components: {
        SellerBidTable,
    },
    mixins: [msgType],
};
</script>

<style lang="less" scoped>
.seller-index {
    /deep/ .i-modals {
        .ivu-modal-mask {
            top: 88px;
            z-index: 1 !important;
        }

        .ivu-modal-wrap {
            top: 88px;
            z-index: 1 !important;
            .ivu-modal {
                top: 300px;
            }
        }
    }
}
</style>
